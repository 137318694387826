import _createStore from "./create-store.js";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Original - @Gozola.
// https://gist.github.com/Gozala/1269991
// This is a reimplemented version (with a few bug fixes).
var createStore = _createStore;
exports = weakMap;

function weakMap() {
  var privates = createStore();
  return {
    "get": function (key, fallback) {
      var store = privates(key);
      return store.hasOwnProperty("value") ? store.value : fallback;
    },
    "set": function (key, value) {
      privates(key).value = value;
      return this || _global;
    },
    "has": function (key) {
      return "value" in privates(key);
    },
    "delete": function (key) {
      return delete privates(key).value;
    }
  };
}

export default exports;